<template>
	<div class="card-payment" :class="{ 'card-payment-error': cardPaymentError, submitting: isFormSubmitting }">
		<div class="transaction-form card-payment-bg">
			<div v-show="stripeReady" class="form-bar my-0">
				<div class="form-border-radius no-gutters my-2">
					<div class="payment-element-wrapper" id="payment-element"></div>
					<div class="payment-element-wrapper" id="address-element"></div>
				</div>
			</div>
			<div v-show="!stripeReady" style="height: 120px">
				<div class="loader-wrapper h-100">
					<div class="loader-block">
						<HollowDotsSpinner
							class="d-flex align-items-center"
							:animation-duration="1000"
							:dot-size="14"
							:dots-num="3"
							:color="primaryColor"
						/>
					</div>
				</div>
			</div>
		</div>
		<div v-html="resultErrorMessage" class="error-msg"></div>
	</div>
</template>

<script>
	import HollowDotsSpinner from '~/stan-vue-shared/components/HollowDotsSpinner'
	import { getThemeColors } from '~/stan-vue-shared/components/utils.js'

	const loadStripe = async () => {
		return new Promise((resolve, reject) => {
			const script = document.createElement('script')
			const scriptTag = document.getElementsByTagName('script')[0]
			script.src = '//js.stripe.com/v3/'
			scriptTag.parentNode.insertBefore(script, scriptTag)
			script.onload = () => {
				resolve()
			}
		})
	}

	const loadCaptcha = async site_key => {
		return new Promise((resolve, reject) => {
			const script = document.createElement('script')
			const scriptTag = document.getElementsByTagName('script')[0]
			script.src = `https://www.google.com/recaptcha/enterprise.js?render=${site_key}`
			scriptTag.parentNode.insertBefore(script, scriptTag)
			script.onload = () => {
				resolve()
			}
		})
	}

	export default {
		name: 'StripePaymentElement',
		components: {
			HollowDotsSpinner,
		},
		props: {
			fcpComplete: { type: Boolean, default: false },
			isFormSubmitting: { type: Boolean },
			stripeAccountId: { type: String },
			amount: { type: Number, default: 0 },
			currency: { type: String, default: 'USD' },
			showWallet: { type: Boolean, default: true },
			username: { type: String, default: '' },
			fanName: { type: String, default: '' },
			fanEmail: { type: String, default: '' },
			// orderId: { type: String },
			theme: { type: String },
			store: { type: Object },
		},
		data() {
			return {
				name: 'stripe',
				stripe: null, // ref to instance of Stripe SDK
				elements: null, // ref to instance of Stripe Elements
				stripeReady: false,
				paymentElementError: null,
				resultErrorMessage: '',
			}
		},
		emits: ['paymentSuccess', 'paymentError', 'gatewayAvailable'],
		computed: {
			cardPaymentError() {
				return this.resultErrorMessage !== '' || this.paymentElementError
			},
			primaryColor() {
				return this.store?.data?.primary_color || '#5383ff'
			},
			secondaryColor() {
				return this.store?.data?.secondary_color
			},
			walletSetting() {
				return this.showWallet ? 'auto' : 'never'
			},
			computedUsername() {
				return this.username || this.$route.params.username
			},
		},
		methods: {
			async initStripe() {
				await loadStripe()
				await loadCaptcha(this.$config.captcha_site_key)
				grecaptcha.enterprise.ready(() => {
					this.createPaymentElement()
				})
			},
			createPaymentElement() {
				const stripeOptions = {}
				const stripe_key = this.stripeProcessingAccount || process.env.NUXT_ENV_STRIPE_PK
				this.stripe = Stripe(stripe_key, stripeOptions)

				const appearance = this.getPaymentElementAppearance()
				const elementsOptions = {
					mode: 'payment',
					amount: this.amount * 100, // TODO: gotta take care of this for all currencies
					currency: this.currency.toLowerCase(),
					paymentMethodCreation: 'manual',
					onBehalfOf: this.stripeAccountId,
					loader: 'auto',
					appearance,
				}
				this.elements = this.stripe.elements(elementsOptions)

				const paymentElement = this.elements.create('payment', {
					wallets: {
						applePay: 'auto',
						googlePay: 'auto',
					},
					defaultValues: {
						billingDetails: {
							name: this.fanName,
							email: this.fanEmail,
						},
					},
					fields: {
						billingDetails: {
							name: 'never',
							email: 'never',
						},
					},
				})
				if (document.getElementById('payment-element')) paymentElement.mount('#payment-element')

				paymentElement.on('change', async event => {
					if (event.elementType === 'payment' && event.value?.type === 'afterpay_clearpay') {
						var addressElement = this.elements.create('address', {
							mode: 'shipping',
							fields: { phone: 'never', name: 'never' },
							defaultValues: {
								name: this.fanName,
							},
						})
						if (document.getElementById('address-element')) addressElement.mount('#address-element')
					} else {
						var addressElement = this.elements.getElement('address')
						addressElement?.destroy()
					}

					if (event.error) {
						this.paymentElementError = event.error.message
					} else {
						this.paymentElementError = null
					}
				})

				this.stripeReady = true
				this.$emit('gatewayAvailable', {
					name: 'stripe2',
					available: true,
				})
			},
			async processPayment(orderId) {
				const token = await grecaptcha.enterprise.execute(this.$config.captcha_site_key, {
					action: 'CreatePaymentIntent',
				})
				this.$emit('paymentInit')
				this.resultErrorMessage = ''
				this.paymentElementError = null

				// Trigger form validation and wallet collection
				const { error: submitError } = await this.elements.submit()
				if (submitError) {
					this.paymentElementError = submitError.message
					this.$emit('paymentError', { message: submitError.message })
					return
				}
				// Get front-end url
				let frontendURI = process.env.NUXT_ENV_FRONT_URL.replace('www.', '')
				const { error, confirmationToken } = await this.stripe.createConfirmationToken({
					elements: this.elements,
					params: {
						payment_method_data: {
							billing_details: {
								name: this.fanName,
								email: this.fanEmail,
							},
						},
						return_url: frontendURI,
					},
				})

				if (error) {
					this.$notify({
						group: '1',
						title: 'There was an issue initializing the Stripe payment',
						text: 'Please try again in a few moments.',
						type: 'error',
					})
					this.$sentry.captureException(error)
				}

				const processPaymentPayload = {
					order_id: orderId,
					amount: this.amount,
					currency: this.currency,
					processor: 'stripe',
					processor_data: {
						on_behalf_of: this.stripeAccountId,
						confirmation_token: confirmationToken,
					},
					captcha_token: token,
				}

				// Create the PaymentIntent
				return this.$axios
					.post('v1/integrations/payment/create-confirm-intent', processPaymentPayload, { baseURL: process.env.NUXT_ENV_PYTHON_API })
					.then(async response => {
						if (response.error) {
							// handle server error
							this.resultErrorMessage = result.error.message
							this.$emit('paymentError', { message: result.error.message })
							return
						} else if (response.data.status === 'requires_action') {
							const { error, paymentIntent } = await this.stripe.handleNextAction({
								clientSecret: response.data.client_secret,
							})

							if (paymentIntent) {
								this.$emit('paymentSuccess')
							}

							if (error) {
								// handle next action error
								this.resultErrorMessage = error.message
								this.$emit('paymentError', { message: error.message })
								return
							}
						} else {
							// handle payment success
							this.$emit('paymentSuccess')
						}
					})
					.catch(error => {
						this.$notify({
							group: '1',
							title: 'Something went wrong',
							text: 'Please reach out to our support at <a href="mailto:friends@stanwith.me">friends@stanwith.me</a>',
							type: 'error',
						})
						throw error
					})
			},
			getPaymentElementAppearance() {
				const themeColors = getThemeColors(this.theme, this.primaryColor, this.secondaryColor)

				const appearance = {
					theme: 'stripe',
					variables: {
						fontSizeBase: '14px',
						focusBoxShadow: 'transparent',
						colorPrimary: this.primaryColor,
						colorBackground: themeColors.secondaryColor,
						colorText: themeColors.primaryTextColor,
						colorTextSecondary: themeColors.secondaryTextColor,
						colorTextPlaceholder: themeColors.secondaryTextColor,
					},
				}
				switch (this.theme) {
					case 'kels':
						appearance.variables.theme = 'none'
						appearance.variables.borderRadius = '0px'
						break
					case 'moderno':
					case 'tyla':
					case 'stone':
						appearance.variables.borderRadius = '0px'
						break
					case 'nightview':
						appearance.variables.borderRadius = '10px'
						break
					case 'eclipse':
						appearance.variables.borderRadius = '10px'
						appearance.variables.colorPrimary = '#ffffff'
						appearance.variables.colorText = '#000000'
						appearance.variables.colorTextPlaceholder = '#999'
						appearance.variables.colorBackground = '#ffffff'
						appearance.variables.colorTextSecondary = '#999'
						appearance.variables.colorIconTab = this.primaryColor
						appearance.variables.colorIconTabSelected = this.primaryColor
						appearance.rules = {
							'.Label': {
								color: '#ffffff',
							},
							'.TabLabel': {
								color: '#999',
							},
							'.TabLabel--selected': {
								color: themeColors.primaryTextColor,
							},
						}
						break
					default:
						break
				}
				if (!appearance.rules) appearance.rules = {}
				appearance.rules['.RedirectText'] = {
					fontSize: '0px',
				}

				appearance.rules['.Tab--selected'] = {
					color: this.primaryColor,
					borderColor: themeColors.secondaryColor,
				}
				appearance.rules['.Tab--selected'] = {
					color: themeColors.primaryColor,
					borderColor: themeColors.secondaryColor,
					boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px ${themeColors.primaryColor}`,
				}

				appearance.rules['.Input'] = {
					padding: '16px',
					lineHeight: '18px',
				}

				return appearance
			},
		},
		watch: {
			fcpComplete(isReady) {
				if (isReady) {
					this.$nextTick(async () => {
						await this.initStripe()
					})
				}
			},
			fanName: {
				immediate: true,
				handler(newVal) {
					this.elements?.getElement('payment')?.update({
						defaultValues: {
							billingDetails: {
								name: newVal,
							},
						},
					})
				},
			},
			fanEmail: {
				immediate: true,
				handler(newVal) {
					this.elements?.getElement('payment')?.update({
						defaultValues: {
							billingDetails: {
								email: newVal,
							},
						},
					})
				},
			},
		},
	}
</script>

<style lang="scss" scoped>
	.payment-element-wrapper {
		overflow: unset;
		margin: 10px 0;
	}

	#payment-element::v-deep {
		margin: -4px;
		& > div {
			width: calc(100% - 2px);
			iframe {
				margin: 1px !important;
				width: 100% !important;
			}
		}
	}

	@supports (-webkit-appearance: -apple-pay-button) {
		.apple-pay-button {
			display: inline-block;
			-webkit-appearance: -apple-pay-button;
			width: 100%;
			height: 45px;
			:hover {
				cursor: pointer;
				filter: brightness(1.2);
			}
		}
		.apple-pay-button-black {
			-apple-pay-button-style: black;
		}
		.apple-pay-button-white {
			-apple-pay-button-style: white;
		}
		.apple-pay-button-white-with-line {
			-apple-pay-button-style: white-outline;
		}
	}

	@supports not (-webkit-appearance: -apple-pay-button) {
		.apple-pay-button {
			display: inline-block;
			background-size: 100% 60%;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			border-radius: 5px;
			padding: 0px;
			box-sizing: border-box;
			min-width: 200px;
			min-height: 32px;
			max-height: 64px;
			width: 100%;
		}
		.apple-pay-button-black {
			background-image: -webkit-named-image(apple-pay-logo-white);
			background-color: black;
		}
		.apple-pay-button-white {
			background-image: -webkit-named-image(apple-pay-logo-black);
			background-color: white;
		}
		.apple-pay-button-white-with-line {
			background-image: -webkit-named-image(apple-pay-logo-black);
			background-color: white;
			border: 0.5px solid black;
		}
	}
	#payment-request-element {
		height: 45px;
		background: #2c2e2f;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			height: 25px;
		}
		&:hover {
			cursor: pointer;
			filter: brightness(1.2);
		}
	}
</style>
<style>
	.grecaptcha-badge {
		display: none !important;
	}
</style>
