<template>
	<div :class="{ submitting: isFormSubmitting }">
		<div id="paypal-button-container"></div>
	</div>
</template>

<script>
	const loadCaptcha = async site_key => {
		return new Promise((resolve, reject) => {
			const script = document.createElement('script')
			const scriptTag = document.getElementsByTagName('script')[0]
			script.src = `https://www.google.com/recaptcha/enterprise.js?render=${site_key}`
			scriptTag.parentNode.insertBefore(script, scriptTag)
			script.onload = () => {
				resolve()
			}
		})
	}

	export default {
		name: 'PaypalPayment2',
		props: {
			username: {
				type: String,
				require: true,
			},
			pageId: {
				type: Number,
				require: true,
			},
			amount: {
				type: Number,
				require: true,
				default: 0,
			},
			currency: {
				type: String,
				require: true,
				default: 'USD',
			},
			fcpComplete: {
				type: Boolean,
				default: false,
			},
			isPaid: {
				type: Boolean,
				default: false,
			},
			triggerSubmit: {
				type: Function,
			},
			isFormSubmitting: {
				type: Boolean,
			},
			isProductRecurring: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				name: 'paypal2',
				orderId: undefined,
				clientId: undefined,
				merchantId: undefined,
				paypalOrderId: undefined,
				paypalReady: false,
			}
		},
		computed: {
			paymentData() {
				return {
					id: this.paypalOrderId,
					transaction_origin: 'user',
				}
			},
		},
		watch: {
			async fcpComplete(isReady) {
				if (isReady) {
					this.$nextTick(async () => {
						await this.preInit()
					})
				}
			},
			async isPaid() {
				if (!this.paypalReady) {
					this.$nextTick(async () => {
						await this.init()
					})
				}
			},
		},
		methods: {
			async preInit() {
				await loadCaptcha(this.$config.captcha_site_key)
				grecaptcha.enterprise.ready(() => {
					this.init()
				})
			},
			async init() {
				try {
					const response = await this.$axios.get(`v1/integrations/paypal/get_keys?username=${this.username}`)
					this.clientId = response.data.client_id
					this.merchantId = response.data.merchant_id
				} catch (err) {
					console.error(err)
					this.$nextTick(() => {
						this.$emit('gatewayAvailable', { name: this.name, available: false })
					})
					return
				}
				if (!this.isPaid || !this.clientId || !this.merchantId) {
					this.$nextTick(() => {
						this.$emit('gatewayAvailable', { name: this.name, available: false })
					})
					return
				}

				if (this.isProductRecurring) {
					// recurring products are not supported for now while we work on one-off payments
					this.$emit('gatewayAvailable', { name: this.name, available: false })
					return
				} else {
					await new Promise((resolve, reject) => {
						const script = document.createElement('script')
						const scriptTag = document.getElementsByTagName('script')[0]
						const disabledFunding = [
							'card',
							'bancontact',
							'blik',
							'eps',
							'giropay',
							'ideal',
							'mercadopago',
							'mybank',
							'p24',
							'sepa',
							'sofort',
							'paylater',
							'venmo',
						]
						script.src = `https://www.paypal.com/sdk/js?&client-id=${this.clientId}&merchant-id=${this.merchantId}&currency=${
							this.currency
						}&disable-funding=${disabledFunding.join(',')}`
						scriptTag.parentNode.insertBefore(script, scriptTag)
						script.onload = () => {
							resolve()
						}
					})
					this.$emit('gatewayAvailable', { name: this.name, available: true })
					const self = this
					// Create Order Button
					await paypal
						.Buttons({
							onClick: async function (data, actions) {
								const canMoveAhead = await self.triggerSubmit(self.name)
								if (!canMoveAhead) {
									return actions.reject()
								} else {
									self.$emit('paymentInit') // TODO: modify this to fit new flow
									return actions.resolve()
								}
							},
							createOrder: async function (data, actions) {
								return self.paypalOrderId
							},
							onApprove: async function (data, actions) {
								const capture_status = await self.captureOrderPayment(self.paypalOrderId)
								if (capture_status == 'COMPLETED') {
									self.$emit('paymentSuccess')
								} else {
									self.$emit('paymentError', { message: 'Payment could not be processed.' })
								}
							},
							onCancel: async function () {
								self.$emit('paymentCancel')
								// Reload to clear old order
								await self.init()
							},
							onError: async function (error) {
								// https://developer.paypal.com/docs/regional/th/checkout/integration-features/handle-errors/#link-buyercheckouterror
								// Note: This error handler is a catch-all. Errors at this point are not expected to be handled beyond showing a generic error message or page.
								self.$emit('paymentError', { message: 'Payment could not be processed.' })
								this.$sentry.captureException(new Error('Could not process payment using Paypal'), {
									extra: {
										error,
										clientId: this.clientId,
										merchantId: this.merchantId,
										paypalOrderId: this.paypalOrderId,
									},
								})
								// Reload to clear old order
								await self.init()
							},
							style: {
								color: 'black',
								disableMaxWidth: true,
							},
						})
						.render('#paypal-button-container')
				}
				this.paypalReady = true
				setTimeout(() => this.$emit('gatewayAvailable', { name: this.name, available: true }), 20)
			},
			async captureOrderPayment(paypalOrderId) {
				const response = await this.$axios.post('v1/integrations/paypal/order/capture', { order_id: paypalOrderId })
				return response.data?.status
			},
			async processPayment(orderId) {
				const token = await grecaptcha.enterprise.execute(this.$config.captcha_site_key, {
					action: 'CreatePaymentIntent',
				})
				this.orderId = orderId
				const processPaymentPayload = {
					order_id: orderId,
					amount: this.amount,
					currency: this.currency,
					processor: 'paypal',
					processor_data: {},
					captcha_token: token,
				}

				// Create the PaymentIntent
				return this.$axios
					.post('v1/integrations/payment/create-confirm-intent', processPaymentPayload, { baseURL: process.env.NUXT_ENV_PYTHON_API })
					.then(async response => {
						if (response.error) {
							// handle server error
							this.resultErrorMessage = result.error.message
							this.$emit('paymentError', { message: result.error.message })
							return
						}
						this.paypalOrderId = response.data.paypal_order_id
					})
					.catch(error => {
						this.$notify({
							group: '1',
							title: 'Something went wrong',
							text: 'Please reach out to our support at <a href="mailto:friends@stanwith.me">friends@stanwith.me</a>',
							type: 'error',
						})
						throw error
					})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.submitting {
		pointer-events: none;
		filter: opacity(0.3);
	}

	#paypal-button-container::v-deep {
		iframe {
			z-index: 0 !important;
		}
	}
</style>
